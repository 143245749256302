import React, { useState, useEffect, useMemo } from 'react';
import styles from './EditDiv.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import DropDown from '../DropDownBox/Dropdown';
import BlueLogo from '../../assets/BlueLogo.png';
import DatePicker from '../DatePicker/DatePicker';
import CommentBox from '../CommentBox/CommentBox';
import TickBox from '../TickBox/TickBox';
import TwoButtonsInput from '../TwoButtonsInput/TwoButtonsInput';

// Helper function to render individual edit components
const RenderEditComponent = ({ item, selectedValue, onValueChange, checkBoxState, onCheckBoxClick, invalid, isSplitItem = false }) => {
  const { editType, dataName, data: fieldData, options, checked, colour } = item;

  // Apply special class for split items
  const getContainerClass = (baseClass) => {
    return isSplitItem ? `${baseClass} ${styles.splitItemContainer}` : baseClass;
  };

  switch (editType) {
    case 'NonEditable':
      return <div className={getContainerClass(styles.NonEditable)}>{fieldData}</div>;
    case 'Date':
      return (
        <div className={getContainerClass(styles.commentHolder)}>
          {(() => {
            const initialValue = selectedValue !== undefined ? selectedValue : fieldData;
            if (dataName === "Invoice Date") {
              return <DatePicker dataName={dataName} initialDate={initialValue} onDatesChange={onValueChange} keepEmpty={false} visableIcon={true} />;
            } else if (initialValue && initialValue.length === 0) {
              return <DatePicker dataName={dataName} initialDate={initialValue} onDatesChange={onValueChange} keepEmpty={true} />;
            } else {
              return <DatePicker dataName={dataName} initialDate={initialValue} onDatesChange={onValueChange} />;
            }
          })()}
        </div>
      );
    case 'Btns&Other':
      return (
        <div className={getContainerClass(styles.commentHolder)}>
          <TwoButtonsInput FirstBtn={fieldData.BtnOneValue} SecondBtn={fieldData.BtnTwoValue} OtherPlaceHolder={fieldData.OtherPlaceHolder} hexCode={fieldData.BtnColour} onValueChange={onValueChange} initialData={selectedValue !== undefined ? selectedValue : fieldData?.InitialValue} />
        </div>
      );
    case 'DropDown':
      return (
        <div className={getContainerClass(styles.commentHolder)}>
          <DropDown options={options} initialSelectedValue={selectedValue !== undefined ? selectedValue : fieldData} onValueChange={onValueChange} PopUp={true} />
        </div>
      );
    case 'MultiDropDown':
      return (
        <div className={getContainerClass(styles.commentHolder)}>
          <DropDown options={options} initialSelectedValue={selectedValue !== undefined ? selectedValue : fieldData} onValueChange={onValueChange} PopUp={true} mode='multi' />
        </div>
      );
    case 'CheckBox':
      const currentCheckedState = checkBoxState !== undefined ? checkBoxState : checked;
      return (
        <div className={getContainerClass(styles.commentHolder)}>
          <TickBox
            type={currentCheckedState || false}
            tickClick={() => {
              const newValue = !currentCheckedState;
              onValueChange(newValue); // Update selectedValues first
              onCheckBoxClick(dataName, currentCheckedState); // Then update internal checkbox state if needed
            }}
            coloureis={colour}
          />
        </div>
      );
    case 'NumberTextBox':
      return (
        <div className={getContainerClass(styles.TextBoxHolder)}>
          <CommentBox initialData={selectedValue !== undefined ? selectedValue : fieldData} onValueChange={onValueChange} integerOnly={true} invalid={invalid} />
        </div>
      );
    case 'TextBox':
    case 'LargeTextBox':
      return (
        <div className={getContainerClass(styles[`${editType}Holder`])}>
          <CommentBox initialData={selectedValue !== undefined ? selectedValue : fieldData} onValueChange={onValueChange} invalid={invalid} />
        </div>
      );
    default:
      return null;
  }
};

function EditDiv({ data, handleUpdateClick, setDisplayEditDiv, actionType, required = false }) {
  const [selectedValues, setSelectedValues] = useState({});
  const [checkBoxStates, setCheckBoxStates] = useState({});
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [total, setTotal] = useState(0);

  // Initialise checkbox states and selectedValues from data.DataEdit
  useEffect(() => {
    const initialCheckBoxStates = {};
    const initialSelectedValues = {};
    if (data && data.DataEdit) { // Add check for data and DataEdit
      data.DataEdit.forEach(({ dataName, checked, data: fieldData, editType }) => {
        // Initialise selectedValues based on fieldData, except for checkboxes
        if (editType !== 'CheckBox' && fieldData !== undefined) {
          initialSelectedValues[dataName] = fieldData;
        }
        // Initialise selectedValues and checkBoxStates for checkboxes
        if (editType === 'CheckBox') {
          const initialChecked = checked !== undefined ? checked : false;
          initialSelectedValues[dataName] = initialChecked;
          initialCheckBoxStates[dataName] = initialChecked;
        }
        // Special case for Btns&Other initial value if needed
        if (editType === 'Btns&Other' && fieldData?.InitialValue !== undefined) {
          initialSelectedValues[dataName] = fieldData.InitialValue;
        }
      });
    }
    setCheckBoxStates(initialCheckBoxStates);
    setSelectedValues(initialSelectedValues);
  }, [data]); // Rerun if the whole data object changes

  // Calculate total from both fieldData and selectedValues
  useEffect(() => {
    if (data?.DataEditTitle === "Edit Allowances" && data?.DataEdit) { // Add checks
      const sum = data.DataEdit.reduce((acc, { dataName }) => { // Only need dataName here
        // Skip the key that should be excluded (e.g., 'allowance')
        if (dataName === 'Total Allowance' || dataName === 'Holiday Allowance') {
          return acc;
        }

        // Use selectedValues for calculation
        const value = selectedValues[dataName];
        const numVal = Number(value); // selectedValues should hold the current number

        return acc + (isNaN(numVal) ? 0 : numVal);
      }, 0);
      setTotal(sum);
    }
  }, [data?.DataEditTitle, data?.DataEdit, selectedValues]);

  const updateSelectedValue = (dataName) => (newValue) => {
    // console.log(`Updating ${dataName} with ${newValue}`); // Keep for debugging if needed
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dataName]: newValue
    }));
  };

  const validateAndSubmit = () => {
    let invalids = [];
    const currentDataEdit = data?.DataEdit || []; // Handle potential undefined data

    if (required) {
      currentDataEdit.forEach(({ editType, dataName, split, pairWith }) => {
        // Don't validate items that are only part of a split pair (handled by the primary)
        if (currentDataEdit.some(d => d.split && d.pairWith === dataName)) return;

        const value = selectedValues[dataName];
        let isRequired = true;

        if (data.DataEditTitle === 'New Client') {
          // Specific optional fields for New Client
          if (['Address 2', 'Town', 'Comment'].includes(dataName)) {
            isRequired = false;
          }
        }
        // General check for required TextBoxes, LargeTextBoxes (and potentially others)
        if (isRequired && (editType === 'TextBox' || editType === 'LargeTextBox') && !value) {
          invalids.push(dataName);
        }
        // Add more validation rules here if needed for other types like DropDown, Date, etc.
        // Example: Check if a required DropDown has a value
        // if (isRequired && editType === 'DropDown' && (!value || value === '')) { // Assuming empty string is invalid
        //   invalids.push(dataName);
        // }
      });
    }

    if (invalids.length > 0) {
      setInvalidInputs(invalids);
      const timer = setTimeout(() => {
        setInvalidInputs([]);
      }, 1500); // Increased timeout slightly
      return () => clearTimeout(timer);
    } else {
      // Filter out internal properties before submitting if needed
      const valuesToSubmit = { ...selectedValues };
      if (data?.DataEditTitle === "Edit Allowances") {
        valuesToSubmit.total = total.toFixed(2); // Add total for allowances
      }

      // Remove temporary/internal values if necessary
      handleUpdateClick(data.DataEditTitle, valuesToSubmit);
      handleClose();
    }
  };

  const handleCheckBoxClick = (dataName, currentState) => {
    setCheckBoxStates(prev => ({ ...prev, [dataName]: !currentState }));
    // updateSelectedValue(dataName)(!currentState) // This is now handled by RenderEditComponent's tickClick
  };

  const handleClose = () => {
    setDisplayEditDiv(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  // Pre-process data to handle split rows
  const processedDataEdit = useMemo(() => {
    if (!data || !data.DataEdit) return []; // Handle cases where data might not be ready

    const pairedItems = new Set();
    const result = [];
    const dataMap = new Map(data.DataEdit.map(item => [item.dataName, item]));

    data.DataEdit.forEach(item => {
      if (pairedItems.has(item.dataName)) {
        return; // Skip if already handled as a secondary item in a split pair
      }

      // Check if this item initiates a split and its pair exists
      if (item.split && item.pairWith && dataMap.has(item.pairWith)) {
        const pairedItem = dataMap.get(item.pairWith);
        // Ensure the paired item hasn't already been processed as a primary item itself
        if (!pairedItems.has(pairedItem.dataName) && !pairedItem.split) { // Paired item shouldn't initiate its own split
          result.push({
            isSplit: true,
            primary: item,
            secondary: pairedItem,
            // Use a combination of names for a stable key
            key: `${item.dataName}-${pairedItem.dataName}`
          });
          pairedItems.add(item.dataName);    // Mark primary as handled
          pairedItems.add(pairedItem.dataName); // Mark secondary as handled
        } else {
          // If the pair doesn't exist or is invalid, render the primary item normally
          result.push({ ...item, key: item.dataName });
        }
      } else {
        // Regular item (not initiating a split or pair not found/invalid)
        result.push({ ...item, key: item.dataName });
      }
    });

    return result;
  }, [data]); // Rerun memoization if data object changes

  // Handle cases where data might not be loaded yet
  if (!data) {
    return (
      <div className={styles.backGround} style={{ position: 'fixed' }} onClick={handleClose}>
        <div className={styles.form} onClick={handleFormClick}>
          Loading...
        </div>
      </div>
    )
  }

  return (
    <div className={styles.backGround} style={{ position: 'fixed' }} onClick={handleClose}>
      <div
        className={styles.form}
        style={{
          width: data.DataEditTitle === "Invoice Request" ? "auto" :
            data.DataEditTitle === "Edit Invoice Request" ? "401px" : "418px"
        }}
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>{data.DataEditTitle}</div>

        <ul className={styles.List}>
          {processedDataEdit.map((item) => {
            if (item.isSplit) {
              // Single-line split row layout
              return (
                <li key={item.key} className={styles.splitDataRow}>
                  <div className={styles.splitRowContainer}>
                    {/* Primary Item - label and input inline */}
                    <div className={styles.splitItemPrimary}>
                      <div className={styles.dataTitle}>
                        {item.primary.dataName + ':'}
                      </div>
                      <RenderEditComponent
                        item={item.primary}
                        selectedValue={selectedValues[item.primary.dataName]}
                        onValueChange={updateSelectedValue(item.primary.dataName)}
                        checkBoxState={checkBoxStates[item.primary.dataName]}
                        onCheckBoxClick={handleCheckBoxClick}
                        invalid={invalidInputs.includes(item.primary.dataName)}
                        isSplitItem={true}
                      />
                    </div>
                    {/* Secondary Item - label and input inline */}
                    <div className={styles.splitItemSecondary}>
                      <div className={styles.dataTitle}>
                        {item.secondary.dataName + ':'}
                      </div>
                      <RenderEditComponent
                        item={item.secondary}
                        selectedValue={selectedValues[item.secondary.dataName]}
                        onValueChange={updateSelectedValue(item.secondary.dataName)}
                        checkBoxState={checkBoxStates[item.secondary.dataName]}
                        onCheckBoxClick={handleCheckBoxClick}
                        invalid={invalidInputs.includes(item.secondary.dataName)}
                        isSplitItem={true}
                      />
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li key={item.key} className={styles.dataRow}>
                  <div className={styles.dataTitle}>
                    {item.dataName + ':'}
                  </div>
                  <RenderEditComponent
                    item={item}
                    selectedValue={selectedValues[item.dataName]}
                    onValueChange={updateSelectedValue(item.dataName)}
                    checkBoxState={checkBoxStates[item.dataName]}
                    onCheckBoxClick={handleCheckBoxClick}
                    invalid={invalidInputs.includes(item.dataName)}
                  />
                </li>
              );
            }
          })}

          {/* Total row for Edit Allowances */}
          {data.DataEditTitle === "Edit Allowances" && (
            <li className={styles.dataRow}>
              <div className={styles.dataTitle}>
                New Total:
              </div>
              <div className={styles.NonEditable}>
                {total.toFixed(2)}
              </div>
            </li>
          )}
        </ul>

        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={validateAndSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
      </div>
    </div>
  );
}

export default EditDiv;